import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ArticlesListPage from './pages/ArticlesListPage';
import ArticlePage from './pages/ArticlePage';
import NotFoundPage from './pages/NotFoundPage';
import generateSitemap from './sitemap-generator.js';
import NavBar from './NavBar';
import Footer from './Footer';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <NavBar />
          <div id="page-body">
            <Switch>
              <Route path="/" component={ArticlesListPage} exact />
              <Route path="/about" component={AboutPage} />
              <Route path="/category/:name" component={ArticlesListPage} />
              <Route path="/story/:year/:month/:name/(.*)" component={ArticlePage} />
              <Route path="/sitemap-gen" render={() => <generateSitemap />} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
} 

export default App;
