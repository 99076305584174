import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import ArticlesList from '../components/ArticlesList';
import ReactPaginate from 'react-paginate';


const ArticlesListPage = ({match}) => {
    
    const name = match.params.name;  
    
    const [articles, setArticleInfo] = useState([]);


    const [pageCount, setPageCount] = useState(1); 
    const [isLoaded, setisLoaded] = useState(false);
    const [currentPage, setcurrentPage] = useState(0); 
    const [query, setQuery] = useState('startups'); 

    const URL = `https://bharatdaily.in/content/article-list.php?c=${name}&p=${currentPage}`;


        
  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
    handleFetch();
  };


   const handleFetch = () => {
    fetch(URL)
      .then(response => response.json())
      .then(body => {
        setArticleInfo(body.article);
            setPageCount(body.npages); 
            setisLoaded(true);
      })
      .catch(error => console.error('Error', error));
  };

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`https://bharatdaily.in/content/article-list.php?c=${name}&p=${currentPage}`);
            const body = await result.json();
            setArticleInfo(body.article);
            setPageCount(body.npages); 
            setisLoaded(true);
        }
        fetchData();
    }, [name,currentPage]);
    
    return (
    <>
    <MetaTags>
            <title>{name}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
    <h1>Articles</h1>
    <ArticlesList articles={articles} />
    {isLoaded ? (
        <ReactPaginate
          pageCount={pageCount}
          pageRange={10}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          containerClassName={'container'}
          previousLinkClassName={'page'}
          breakClassName={'page'}
          nextLinkClassName={'page'}
          pageClassName={'page'}
          disabledClassNae={'disabled'}
          activeClassName={'active'}
        />
      ) : (
        <div>Nothing to display</div>
      )} 
    </>
    );

};
  

export default ArticlesListPage;