require("babel-register")({
  presets: ["es2015", "react"]
});
const fetch = require("node-fetch");
const router = require("./sitemap-routes").default;
const Sitemap = require("react-router-sitemap").default;

const generateSitemap = () => {

 (async () => {
   try {
  	  const posts = [];
  	       
           fetch('https://bharatdaily.in/content/article-list.php')
  .then(response => response.json())
  .then(data => {
    const posts = data
      let idMap = [];

      for(var i = 0; i < posts.length; i++) {
        idMap.push({ id: posts[i].id });
      }
     // console.log(data.l);
      const paramsConfig = {
            "/news/article/:id": idMap
          };
      if(posts.length<1)
      {
      //wait();
      }
      return (
        new Sitemap(router)
            .applyParams(paramsConfig)
            .build("https://www.andhrapradeshjournal.in")
            .save("./public/sitemap.xml")
      );
  });

      
   } catch(e) {
      console.log(e);
    } 
})();
}


export default generateSitemap;