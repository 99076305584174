import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => (
    <nav>
        <ul>
           
            <li>
                <Link to="/category/business">Business</Link>
            </li>
            <li>
                <Link to="/category/finance">Finance</Link>
            </li>
            <li>
                <Link to="/category/technology">Technology</Link>
            </li>
            <li>
                <Link to="/category/arts-entertainment">Arts & Entertainment</Link>
            </li>
            <li>
                <Link to="/category/world">World</Link>
            </li>
            
        </ul>
    </nav>
);

export default NavBar;