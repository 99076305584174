import React from 'react';
import { Link } from 'react-router-dom';

const ArticlesList = ({ articles }) => (
    <>
    {articles.map((article, key) => (
        <Link className="article-list-item" key={key} to={`/story/${article.path}/apj${article.id}/${article.alias}`}>
            <h3>{article.title}</h3>
           
        </Link>
    ))}
    </>
);

export default ArticlesList;