import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import ArticlesList from '../components/ArticlesList';
import NotFoundPage from './NotFoundPage';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const ArticlePage = ({ match }) => {
    const name = match.params.name;
    const path = (match.params.year)+'/'+(match.params.month);
    console.log(path);
    const [articles, setArticleInfo] = useState([]);

        const state = {
      loading: true
    };

    useEffect(() => {
        const fetchData = async () => {
             console.log(`https://bharatdaily.in/content/article-content.php?name=${name}&p=${path}`);
            const result = await fetch(`https://bharatdaily.in/content/article-content.php?name=${name}&p=${path}`);
            const body = await result.json();
            setArticleInfo(body);
            const state = {
              loading: false
            };
        }
        fetchData();
    }, [name, path]);
    if(!state.loading) 
    {
        if (articles[0].title !=null) return <NotFoundPage />
    }        
       

    return (
        <>
        {articles.map((article) => (            
         <>
         <MetaTags>
            <title>{article.title}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
        <h1>{article.title}</h1>
        {ReactHtmlParser(article.content)}
        </>
       ))}
       
        </>
    );
}

export default ArticlePage;